.container {
  left: 0;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.espa {
  max-width: 100%;
  max-height: 100%;
}

.terms {
  color: #0000ee;
}

.terms:hover {
  text-decoration: underline;
}

.adminLoginButton {
  background-color: #075a07;
  color: white;
  border-radius: 3em;
}

.links {
  width: 23%;
}

.centralContent {
  padding-left: 70px;
}

.chamberInfo {
  font-size: 14px;
}

.parentBox {
  display: flex;
}

@media only screen and (max-width: 1550px) and (min-width: 1250px) {
  .links {
    width: 30%;
  }

  .centralContent {
    padding-left: 0px;
  }
  
}

@media only screen and (max-width: 999px)  {
  .chamberInfo {
    font-size: 10px;
  }

  .parentBox {
    display: block;
  }

  .centralContent {
    padding-left: 0px;
  }

  .links {
    width: 100%;
    font-size: 12px;  
  }
}