.appBar {
  background-color: white;
  height: 80px; 
}

.title {
  color: #212529;
  font-weight: bold;
  padding-left: 10px;
}

.grow {
  flex-grow: 1;
}

.logo {
  cursor: pointer;
}

.secondLogo {
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
}

.menuButton {
  margin-right: 20px;
  color: #212529;
}

.menuButtonSecondBar {
  margin-right: 20px;
  font-weight: bold;
}

.iconButton {
  margin-right: 12px;
  color: #212529;
}

.iconButtonSecondBar {
  margin-right: 12px;
}

.menuItem {
  transition: 0.4s;
  color: #0000ee;
  font-size: 18px;
  text-decoration: none;
  padding: 0 10px;
  margin: 0 10px;
}
.menuItem:hover {
  /* background-color: #0f9b0f;
  color: #eea200;
  padding: 10px 10px;
  border-radius: 3em; */
  text-decoration: underline;
}

.initialLinkSecondBar {
  transition: 0.4s;
  font-size: 18px;
  text-decoration: none;
  padding: 0 10px;
  margin: 0 10px;
  font-weight: bold;
}

.initialLinkSecondBar:hover {
  text-decoration: underline;
}

.flagImage {
  width: 40px;
  height: 30px;
}

.secondbarContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.titleSecondBar {
  font-size: 32px;
}

.desktopSection {
  display: flex;
}

.mobileSection {
  display: none;
}

@media only screen and (max-width: 999px)  {

  .appBar {
    background-color: white;
    height: 100px; 
  }

  .titleSecondBar {
    display: none
  }

  .desktopSection {
    display: none;
  }

  .mobileSection {
    display: flex;
  }
}