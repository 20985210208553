html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.default-nav {
  margin: 30px !important;
} 

.html-from-db table {
  /* border-collapse: collapse; */
  text-align: center;
  background-color: #e1e2e2;
  width: 1450px;
}  

.html-from-db th {
  /* border: 1px solid black; */
  
}  

.html-from-db td  {
  /* border: 1px solid black; */
  padding: 15px;
}

@media only screen and (max-width: 1550px) and (min-width: 1250px)   {
  .html-from-db table {
    text-align: center;
    background-color: #e1e2e2;
    width: 1300px;
  } 
}

@media only screen and (max-width: 999px)  {
  .html-from-db table {
    max-width: 100px;
  }  
  
  .html-from-db th {
    display: block; 
  }  
  
  .html-from-db td  {
     display: block; 
  }
}

